<template>
    <section class="section faq-section" v-if="groupedData && groupedData.length > 0">
        <div class="container">
            <slot></slot>
            <div class="column-wrapper fullwidth">
                <div class="column faq" v-for="value in groupedData" :key="value.name">
                    <h3 class="column-title">{{ getEnumVal(value.name) }}</h3>
                    <ul class="faq-questions-wrapper" v-if="value.children && value.children.length > 0">
                        <FaqItem v-for="item in value.children" :data="item" :key="item.id" />
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
//@import component
import FaqItem from "@/components/FaqItem";

import { fetchTemplateContentList } from "@streampac.io/chef_sp_1";

export default {
    inject: ["isAuthenticated"],
    created() {
        setTimeout(() => {
            let enums = this.$store.state.getEnumLookup;

            if (this.filterFaqBy && this.filterFaqBy.length > 0) {
                if (enums && enums.length > 0) {
                    const enumsFilter = enums.filter((item) =>
                        this.filterFaqBy.includes(item.enumValue)
                    );

                    if (enumsFilter && enumsFilter.length > 0) {
                        enumsFilter.forEach((item) => {
                            //this.fetchDataParams.filter.searchTerm = item.id;
                            this.fetchContentGroupedBy(item.id);
                        });
                    }
                }

            } else if (this.filterFaqById && this.filterFaqById.length > 0) {
                // this.fetchContent();

                this.filterFaqById.forEach((item) => {
                    this.fetchContentGroupedBy(item);
                });
            }


            //console.log(this.groupedData);

            //groupedData = alle items
            //console.log(enums);


            //             { id: '2a84aa44-9d94-4117-8646-3b17d12e71cb', enumValue: 'General' }
            //             86
            // :
            //             { id: '7ccc206c-78eb-4753-b60d-34c6bab881ca', enumValue: 'For clubs' }
            //             87
            // :
            //             { id: '858c5c4e-d8f0-4001-9189-da804d6f2494', enumValue: 'Pricing and payment' }
            //             88
            // :


            // this.fetchContentGroupedBy.sort((a, b) => {
            //     console.log(a, b);
            //     return this.filterFaqBy.indexOf(a.name) - this.filterFaqBy.indexOf(b.name);
            // });


        }, 300);
        setTimeout(() => {
            //Sort by getting the sortOrder from the enums

            const givenFilters = this.filterFaqBy;
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                //Only return enums which are found in the big list
                const filterDataFromEnums = enums.filter((item) =>
                    givenFilters.includes(item.enumValue)
                );

                const pluck = (array, key) => {
                    return array.map((o) => o[key]);
                };

                if (filterDataFromEnums && filterDataFromEnums.length > 0) {

                    const pluckedEnumIds = pluck(filterDataFromEnums, "id");

                    this.groupedData.sort(function (a, b) {
                        return pluckedEnumIds.indexOf(a.name) - pluckedEnumIds.indexOf(b.name);
                    });

                }

            }

        }, 500);
    },
    props: {
        filterFaqBy: {
            type: Array,
            required: false,
        },
        filterFaqById: {
            type: Array,
            required: false,
        },
    },
    emits: ["doneLoadingFaq"],
    data() {
        return {
            groupedData: [],
            doneLoading: false,
            fetchDataParams: {
                filter: {
                    searchTerm: "",
                },
                paging: {
                    pageNumber: 1,
                    pageSize: 200,
                },
                orderBy: {
                    orderByField: "sortOrder",
                    //orderDesc: true,
                },
                //flattenFields: true,
            },
        };
    },
    components: {
        FaqItem,
    },
    methods: {
        groupBy(array, key) {
            // Return the end result
            return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                (result[currentValue[key]] =
                    result[currentValue[key]] || []).push(currentValue);
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
            }, {}); // empty object is the initial value for result object
        },
        groupByList(list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        },
        fetchContentGroupedBy(groupId) {
            const getContentTypes = this.$store.state.getContentTypes;

            const chosenType = getContentTypes.faq;

            let fetchDataParams = {
                filter: {
                    searchTerm: groupId,
                },
                paging: {
                    pageNumber: 1,
                    pageSize: 200,
                },
                orderBy: {
                    orderByField: "sortOrder",
                    //orderDesc: true,
                },
            };

            fetchTemplateContentList(
                this.isAuthenticated,
                chosenType,
                fetchDataParams
            ).then((response) => {
                let responseObj = {
                    name: groupId,
                    children: response,
                };

                this.groupedData.push(responseObj);

                this.doneLoading = true;

            });
        },
        // fetchContent() {
        // 	const getContentTypes = this.$store.state.getContentTypes;

        // 	const chosenType = getContentTypes.faq;

        // 	const inputData = this.fetchDataParams;

        // 	fetchTemplateContentList(
        // 		this.isAuthenticated,
        // 		chosenType,
        // 		inputData
        // 	).then((response) => {
        // 		let responseVar = response;

        // 		console.log(response);

        // 		if (this.filterFaqById && this.filterFaqById.length > 0) {
        // 			responseVar = response.filter((item) =>
        // 				this.filterFaqById.includes(item.enumerationValueId)
        // 			);
        // 		}
        // 		const groupByCat = this.groupBy(
        // 			responseVar,
        // 			"enumerationValueId"
        // 		);

        // 		// let responseObj = {
        // 		// 	name: groupId,
        // 		// 	children: response,
        // 		// };

        // 		this.groupedData = groupByCat;

        // 		console.log(groupByCat);

        // 		this.doneLoading = true;
        // 	});
        // },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    if (enumFilter && enumFilter.length > 0) {
                        return enumFilter[0].enumValue;
                    }
                } else {
                    return "";
                }
            }

            return "";
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";

// .column-title {
// 	color: #fff;
// 	margin-bottom: 30px;
// }
// .dark .column-title {
// 	color: $black;
// }
// .faq-questions-wrapper {
// 	border-top: 1px solid #fff;
// }
</style>
