<template>
    <HeroPage v-if="pageData" :data="pageData" heroType="center" />

    <PageLayoutBuilder v-if="pageData" :data="pageData">

        <template #section-toggle-pricing-section="section">

            <div class="container subscription-type-toggle">
                <ul>
                    <li><button class="button" :class="{ active: displayMonthlyPriceplans === 'monthly' }"
                            @click="changeSubscriptionVisibility('monthly')">Monthly</button></li>
                    <li><button class="button" :class="{ active: displayMonthlyPriceplans === 'yearly' }"
                            @click="changeSubscriptionVisibility('yearly')">Yearly</button></li>
                </ul>
            </div>

            <section class="section toggle-pricing-section"
                v-if="section.data.elements && displayMonthlyPriceplans == 'yearly'">
                <span class="anchor"></span>
                <div class="container">
                    <div class="column-wrapper contains-priceplan">
                        <template v-for="element in section.data.elements" :key="element.contentId">
                            <template v-if="element.pricePlan && element.pricePlan.relatedPricePlans.length > 0">
                                <div class="column priceplan"
                                    v-for="innerElement in element.pricePlan.relatedPricePlans"
                                    :key="innerElement.contentId">
                                    <SubscriptionOverviewItem :data="innerElement" />
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </section>
            <section class="section toggle-pricing-section"
                v-if="section.data.elements && displayMonthlyPriceplans == 'monthly'">
                <span class="anchor"></span>
                <div class="container">
                    <div class="column-wrapper contains-priceplan">
                        <template v-for="element in section.data.elements" :key="element.contentId">
                            <div class="column priceplan">
                                <SubscriptionOverviewItem :data="element.pricePlan" />
                            </div>
                        </template>
                    </div>
                </div>
            </section>
        </template>
    </PageLayoutBuilder>

    <FaqCollection :filterFaqBy="[
        'General',
        'Pricing and payment',
        'For clubs',
        'TBD 1',
        'TBD 2',
        'TBD 3'
    ]">
        <div class="section-title-wrapper">
            <h2 class="section-title">Frequently asked questions</h2>
            <span class="section-subtitle">Before you contact us, maybe you can find your answer here?</span>
        </div>
    </FaqCollection>
</template>

<script>
import {
    fetchPageBySlug,
} from "@streampac.io/chef_sp_1";

// @import component
import SubscriptionOverviewItem from "@/components/SubscriptionOverviewItem";
import FaqCollection from "@/components/FaqCollection";

import HeroPage from "@/components/HeroPage";

// import PricingCta from "@/components/PricingCta";
import PageLayoutBuilder from "@/components/PageLayoutBuilder";

export default {
    name: "SubscriptionPage",
    inject: ["isAuthenticated"],
    components: {
        HeroPage,
        PageLayoutBuilder,
        FaqCollection,
        SubscriptionOverviewItem
    },
    data() {
        return {
            userJustRegistered: false,
            pageData: null,
            displayMonthlyPriceplans: 'monthly',
            fetchDataParams: {
                paging: {
                    pageNumber: 1,
                    pageSize: 6,
                },
            },
        };
    },
    // watch: {
    //     isAuthenticated() {
    //         this.pageData = null;
    //         setTimeout(() => {
    //             this.fetchPage();
    //         }, 50);
    //     },
    // },
    created() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (queryString && queryString !== "") {
            const isUserJustRegistered = urlParams.get("just-registered");

            if (isUserJustRegistered) {
                this.userJustRegistered = true;
            }
        }

        this.fetchPage();
    },
    methods: {
        fetchPage() {

            fetchPageBySlug(this.isAuthenticated, "memberships").then(
                (response) => {
                    this.pageData = response;
                }
            );

        },
        showLoginPopup() {
            this.$store.commit("showLoginPopup");
        },
        changeSubscriptionVisibility(val) {
            if (val == 'yearly') {
                this.displayMonthlyPriceplans = 'yearly';
            } else {
                this.displayMonthlyPriceplans = 'monthly';
            }
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
